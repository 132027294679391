$(document).ready(function() {
    if ($(".konfigurator").length) {
        $(".wizard").steps({
            headerTag: "h2",
            bodyTag: "section",
            transitionEffect: "slideLeft",
            enableFinishButton: true,
            enablePagination: true,
            enableAllSteps: true,
            onStepChanged: function(event, currentIndex, priorIndex) {
                $('.wizard .content').height($('.step-' + currentIndex).outerHeight() + 75);
            },
            labels: {
                cancel: "Abbrechen",
                current: "Aktuell:",
                pagination: "Navigation",
                finish: "Daten Auswerten",
                next: "Weiter",
                previous: "ZurÃ¼ck",
                loading: "Laden ..."
            }
        });
        $(".actions li a").each(function(i) {
            $(this).addClass('btn');
        });

        $('.wizard .content').height($('.step-0').outerHeight() + 75);
        $(window).on('resize', function() {
            var currentIndex = $(".wizard").steps("getCurrentIndex");
            $('.wizard .content').height($('.step-' + currentIndex).outerHeight() + 75);
        });

        $('#wizard').validate({
            invalidHandler: function(event, validator) {
                $('.wizard .content').height($('.step-1').outerHeight() + 75);
            }
        });
    }


    $(document).on('click', 'a[href="#finish"]', function(e) {
        e.preventDefault();
        var mattress_id = mattress_wizard();
        console.log('Matratzen ID: ' + mattress_id);
        if ($('input#Groesse').val() == '' || $('input#Gewicht').val() == '' || $('input#Alter').val() == '') {
            $("#wizard-t-0").trigger('click');
            $('#wizard').valid()
        } else {

            $('.zurbestellung').attr('href', set_shop_link(mattress_id));

            $('.ergebnis').html(mattress_id);
            $('.auswertungContainer').fadeIn(0);
            $('.auswertung').fadeOut(0).delay(3).fadeIn(1000);
            $('#typ').attr('value', mattress_id);
        }

    });
    $('.auswertungClose').click(function() {
        $('.auswertung').fadeOut(1000);
        $('.auswertungContainer').delay(2).fadeOut(0);
    });

    $(document).on('click', '.kontaktanfrage', function(e) {
        e.preventDefault();
        $('.wizard .content').height($('.step-5').outerHeight() + 75);
        //fade out modal + overlay
        $('.auswertung').fadeOut(1000);
        $('.auswertungContainer').delay(2).fadeOut(0);


        if (!$(".knownButton").length) {
            $(".wizard").steps("add", {
                title: "Ihr Kontakt zu uns"
            });
            $('a[href="#finish"]').addClass('knownButton');
            $("#wizard-p-5").html($('.additionalFields').html());
            $('.actions ul').append('<li style="display: block;" aria-hidden="false"><button type="submit" class="btn">Kontaktanfrage versenden</button></li>');
        }
        $("#wizard-t-5").trigger('click');
    });

});

function set_shop_link(mattress_id) {
    var link = false;
    switch (mattress_id) {
        case 15:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0015-Elax ";
            break;
        case 13:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0013-Elax";
            break;
        case 11:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0011-Elax";
            break;
        case 9:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0009-Elax";
            break;
        case 7:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0007-Elax";
            break;
        case 5:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0005-Elax";
            break;
        case 3:
            link = "https://shop.internet-media.com/epages/18025876.sf/de_DE/?ObjectPath=/Shops/18025876/Products/0003-Elax";
            break;
    }
    return link;
}

function mattress_wizard() {
    var $wizard = $('.wizard');

    // Gewichtsfaktor
    var $size = $('[name="Groesse"]', $wizard);
    var $weight = $('[name="Gewicht"]', $wizard);
    var size = $size.val();
    var weight = $weight.val();
    var gewichtsfaktor = weigthFactor(size, weight);

    var sumValue = addFactor($wizard, $('[data-wizard-calc="add"]', $wizard));
    var sumLength = ($('[data-wizard-calc="add"]:checked').length) + ($('[data-wizard-calc="add"] option:selected').length);
    var sumQuestions = (sumValue / sumLength);
    console.log('Anzahl (' + sumLength + ') / Summe (' + sumValue + ') = ' + sumQuestions);

    ////// ROUNDS UP FOR FEMALE AND ROUNDS OFF FOR MALE//////
    var rounded = 0;
    if ($('#Geschlecht option:selected').val() == 'w') {
        rounded += Math.ceil(sumQuestions);
    }
    if ($('#Geschlecht option:selected').val() == 'm') {
        rounded += Math.floor(sumQuestions);
    }
    console.log('Gerundet: ' + rounded);
    ////// ROUNDS UP FOR FEMALE AND ROUNDS OFF FOR MALE//////


    ////// ROUNDED QUESTION SCORE ADDED TO WEIGHTFACTOR AND DIVIDES IT BY 2 AND ROUNDS AGAIN//////
    var sumTogether = ((rounded + gewichtsfaktor) / 2);
    var sumOverall = 0;
    if ($('#Geschlecht option:selected').val() == 'w') {
        sumOverall += Math.ceil(sumTogether);
    }
    if ($('#Geschlecht option:selected').val() == 'm') {
        sumOverall += Math.floor(sumTogether);
    }

    var endSum = 0;
    if (sumOverall >= 4 && sumOverall <= 5) {
        endSum = 5;
    }
    if (sumOverall >= 6 && sumOverall <= 7) {
        endSum = 7;
    }
    if (sumOverall >= 8 && sumOverall <= 9) {
        endSum = 9;
    }
    if (sumOverall >= 10 && sumOverall <= 11) {
        endSum = 11;
    }
    if (sumOverall >= 12 && sumOverall <= 13) {
        endSum = 13;
    }
    if (sumOverall >= 14 && sumOverall <= 15) {
        endSum = 15;
    }
    return endSum;
    ////// ROUNDED QUESTION SCORE ADDED TO WEIGHTFACTOR AND DIVIDES IT BY 2 AND ROUNDS AGAIN//////

}

//////ADDS VALUES TOGETHER//////
function addFactor($wizard, $toAdd) {
    var sumOfToAdd = 0;
    $toAdd.each(function(i, elm) {
        var $elm = $(elm);
        if (elm.tagName == 'SELECT') {
            sumOfToAdd += parseInt($('option:selected', $elm).val());
            console.log('+ ' + parseInt($('option:selected', $elm).val()));
        }
        if (elm.tagName == 'INPUT' && elm.type == 'checkbox' && $elm.is(':checked')) {
            sumOfToAdd += parseInt($elm.val());
            console.log('+ ' + parseInt($elm.val()));
        }
    });
    return sumOfToAdd;
}


function weigthFactor(size, weight) {
    var iGroesse = parseInt(size);
    var iGewicht = parseInt(weight);
    var temp1 = (iGroesse / iGewicht);
    var gf = 0;

    if (temp1 >= 2.5) {
        gf = 13;
    }
    if (temp1 < 2.5 && temp1 >= 2) {
        gf = 11;
    }
    if (temp1 < 2 && temp1 > 1.68) {
        gf = 9;
    }
    if (temp1 < 1.68) {
        gf = 7;
    }
    console.log('Gewichtsfaktor: ' + gf);
    return gf;
}